import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

interface BuyInBulkProps {
  data: any;
}

const BuyInBulk: React.FC<BuyInBulkProps> = ({ data }) => {
  return (
    <>
      <section className={styles.buybulksection}>
        <div className="container-fluid">
          <div className={styles.buybulkinner}>
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.buybulkheading}>
                  <h2>{data?.title}</h2>
                  <p>{data?.subtitle}</p>
                </div>
              </div>
            </div>
            <div className={`${styles['buybulkcontent']} space-x-6`}>
              <div className="wdRow wdgx-3">
                {data?.items?.map((data: any, i: number) => (
                  <div
                    className="wdCol-lg-4 wdCol-md-4 wdCol-sm-4 wdCol-12"
                    key={i}
                  >
                    <Link href={data.href} className={styles.buybulkcard}>
                      <div className={styles.buybulkimg}>
                        <Image
                          width={600}
                          height={448}
                          quality={75}
                          loading="lazy"
                          src={data.imageSrc}
                          alt={data.alt}
                          title={data.title}
                        />
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BuyInBulk;
